import React, { useState } from 'react';
import Lightbox from "react-image-lightbox"
import images from './images';
import * as styles from './styles.module.scss';

const EuropeanChic = (props) => {
  const { collection } = images
  const initialState = {
    photoIndex: 0,
    isOpen: false,
  };

  const [values, setValues]= useState(initialState);
  const setLightBoxIndex = (index) => {
    setValues({
      ...values,
      selectedIndex: index,
      photoIndex: index,
      isOpen: true,
    });
  };
  return (
    <div className={styles.europeanChic}>
      <div className={styles.headerContainer}>
        <h1>European Chic</h1>
        <p>
          This collection reflects the artist’s observations of European culture and aesthetics.
          It is a reflection rich in history and modern luxury. The art pieces emphasize opulence and
          lavishness to create a strong emotional connection with the visitor.
        </p>
      </div>
      <div className={styles.europeanChicGallery}>
        <div>
          <div className={styles.europeanChicRow}>
            <div><img alt="gallery image" src={collection[0].source} onClick={() => setLightBoxIndex(0)}/></div>
            <div><img alt="gallery image" src={collection[1].source} onClick={() => setLightBoxIndex(1)}/></div>
            <div><img alt="gallery image" src={collection[2].source} onClick={() => setLightBoxIndex(2)}/></div>
          </div>
          <div className={styles.europeanChicRow}>
            <div><img alt="gallery image" src={collection[3].source} onClick={() => setLightBoxIndex(3)}/></div>
            <div><img alt="gallery image" src={collection[4].source} onClick={() => setLightBoxIndex(4)}/></div>
            <div><img alt="gallery image" src={collection[5].source} onClick={() => setLightBoxIndex(5)}/></div>
          </div>
          <div className={styles.europeanChicRow}>
            <div><img alt="gallery image" src={collection[6].source} onClick={() => setLightBoxIndex(6)}/></div>
            <div><img alt="gallery image" src={collection[7].source} onClick={() => setLightBoxIndex(7)}/></div>
          </div>
          <div className={styles.europeanChicRow}>
            <div><img alt="gallery image" src={collection[8].source} onClick={() => setLightBoxIndex(8)}/></div>
            <div><img alt="gallery image" src={collection[9].source} onClick={() => setLightBoxIndex(9)}/></div>
          </div>
          <div className={styles.europeanChicRow}>
            <div><img alt="gallery image" src={collection[10].source} onClick={() => setLightBoxIndex(10)}/></div>
            <div><img alt="gallery image" src={collection[11].source} onClick={() => setLightBoxIndex(11)}/></div>
            <div><img alt="gallery image" src={collection[12].source} onClick={() => setLightBoxIndex(12)}/></div>
          </div>
          <div className={styles.europeanChicRow}>
            <div><img alt="gallery image" src={collection[13].source} onClick={() => setLightBoxIndex(13)}/></div>
            <div><img alt="gallery image" src={collection[14].source} onClick={() => setLightBoxIndex(14)}/></div>
          </div>
          <div className={styles.europeanChicRow}>
            <div><img alt="gallery image" src={collection[15].source} onClick={() => setLightBoxIndex(15)}/></div>
          </div>
        </div>
      </div>
      <div className={styles.europeanChicGallery}>
        { values.isOpen && (
          <Lightbox
            imageTitle={collection[values.photoIndex].name}
            mainSrc={collection[values.photoIndex].source}
            nextSrc={collection[(values.photoIndex + 1) % collection.length].source}
            prevSrc={collection[(values.photoIndex + collection.length - 1) % collection.length].source}
            onCloseRequest={() => setValues({...values, isOpen: false})}
            onMovePrevRequest={() =>
              setValues({
                ...values,
                photoIndex: (values.photoIndex + collection.length - 1) % collection.length,
              })
            }
            onMoveNextRequest={() =>
              setValues({
                ...values,
                photoIndex: (values.photoIndex + 1) % collection.length,
              })
            }
          />
        )}
      </div>
    </div>
  );
}

export default EuropeanChic;
