import goldFish from './images/gold_fish_5.png'
import petalsOfGold from './images/petals_of_gold.png'
import blaze from './images/the_blaze.png'
import goldFishP from './images/gold_fish_(purple).png'
import balance from './images/the_balance.png'
import wisdom from './images/corners_of_wisdom.png'
import Image1 from './images/img_0291.png'
import Image2 from './images/img_0290.png'
import intent from './images/lustrous_intentions.png'
import mood from './images/liberace_mood.png'
import dreams from './images/soft_dreams.png'
import healing from './images/healing_substance.png'
import atoms from './images/fancy_atoms.png'
import conversation from './images/conversation.png'
import tango from './images/tango.png'
import blue from './images/blue.png'

const images = {
  collection: [
    {
      index: 0,
      name: 'Gold Fish',
      source: goldFish,
    },
    {
      index: 1,
      name: 'Petals of Gold',
      source: petalsOfGold,
    },
    {
      index: 2,
      name: 'The Blaze',
      source: blaze,
    },
    {
      index: 3,
      name: 'Gold Fish - Purple',
      source: goldFishP,
    },
    {
      index: 4,
      name: 'The Balance',
      source: balance,
    },
    {
      index: 5,
      name: 'Corners of Wisdom',
      source: wisdom,
    },
    {
      index: 6,
      name: ' ',
      source: Image1,
    },
    {
      index: 7,
      name: ' ',
      source: Image2,
    },
    {
      index: 8,
      name: 'Lustrous Intentions',
      source: intent,
    },
    {
      index: 9,
      name: 'Liberace Mood',
      source: mood,
    },
    {
      index: 10,
      name: 'Soft Dreams',
      source: dreams,
    },
    {
      index: 11,
      name: 'Healing Substance',
      source: healing,
    },
    {
      index: 12,
      name: 'Fancy Atoms',
      source: atoms,
    },
    {
      index: 13,
      name: 'Conversation',
      source: conversation,
    },
    {
      index: 14,
      name: 'Tango',
      source: tango,
    },
    {
      index: 15,
      name: 'Blue',
      source: blue,
    }
  ],
};

export default images;