import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import EuropeanChic from "../../components/ArtStyle/EuropeanChic"

const EuropeanChicPage = () => (
  <Layout>
    <Seo title="European Chic" />
    <EuropeanChic />
  </Layout>
)

export default EuropeanChicPage
